import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/seo'
import TermsAndConditions from '../components/TermsAndConditions/TermsAndConditions'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { TERMS as TERMS_LINK } from '../components/internal-links'
import { generateBreadcrumbsSchema } from '../lib/generate-schema'

const breadcrumbs: Breadcrumb[] = [
  {
    title: 'Homepage',
    url: '/',
  },
  {
    title: 'Terms of Use',
    url: TERMS_LINK,
  },
]

const TNC: React.FC = () => (
  <Layout showBreadcrumbs breadcrumbs={breadcrumbs}>
    <Helmet>
      {process.env.GATSBY_STAGING === 'true' && (
        <meta name="robots" content="noindex" />
      )}
    </Helmet>
    <SEO
      title="Terms of Use: Read About the Terms of Use of Mega"
      description="Read about the terms of use at Mega. Get to know how to use, terms and conditions of usage of the app. Click to read more!  "
      breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
    />
    <TermsAndConditions />
  </Layout>
)

export default TNC
