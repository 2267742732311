const bannedStates = [
  'Assam',
  'Andhra Pradesh',
  'Nagaland',
  'Telangana',
  'Sikkim',
  'Odisha',
]

export const bannedStatesString = (() => {
  const states = bannedStates
  const lastState = states.pop()
  return `${states.join(', ')}, and ${lastState}`
})()

export default bannedStates
